enum Role {
  Provider = 1,
  Admin = 2,
  CalendarAdmin = 3,
  Papper = 4,
  Marketer = 5,
  ContactAdmin = 6,
  Engineer = 7,
  Itstuff = 8,
  ProblemTypeAdmin = 9,
  DiscountTypeAdmin = 10,
  ContentAdmin = 11,
  ClinicalSupportTeam = 12,
  Phlebotomist = 13,
  Acupuncturist = 14,
  Nutritionist = 15,
  Naturopath = 16,
  Osteopath = 17,
  Manager = 18,
  Ob = 19,
  TemplateEditor = 20,
  Pcp = 21,
  MindBody = 22,
  BillingAdmin = 23,
  MedStudent = 24,
  SportsMedicine = 25,
  Colpo = 26,
  Vaccinator = 27,
  MentalHealth = 28,
  Teacher = 29,
  Ucp = 30,
  IudInserter = 31,
  IudRemover = 32,
  Gyn = 33,
  DuplicateReviewer = 35,
  Dermatologist = 36,
  Rn = 37,
  UcsfOb = 38,
  OsteopathOnly = 39,
  MedicationAdmin = 40,
  ClientServices = 41,
  CareNav = 42,
  Pediatrics = 43,
  NexplanonInserter = 44,
  NexplanonRemover = 45,
  PrenatalUltrasound = 46,
  PediatricsAdmin = 47,
  ObgynAdmin = 48,
  PhysicalTherapy = 49,
  MindsetEnterprise = 50,
  Chiropractic = 51,
  EndometrialBiopsy = 52,
  ClinicalProgramsManager = 53,
  HealthCoach = 54,
  MembershipAdvisor = 55,
  PrenatalAdmin = 56,
  PsychiatricServices = 57,
  GoogleNycWellness = 58,
  SpacexWellness = 59,
  IntegrativeHealthLabs = 60,
  HiResAnoscopy = 61,
  FamilyMedicine = 62,
  VirtualPhysician = 63,
  Allergist = 64,
  MemberTechnicalSupport = 65,
  LactationConsultant = 66,
  AdminTrainee = 67,
  MedicalRecordsAdmin = 68,
  AuthsAdmin = 69,
  FrontDeskAdmin = 70,
  VmtFocus = 71,
  Legal = 72,
  Clubhouse = 73,
  VmtAfterHours = 74,
  JaneStreetAdmin = 75,
  GoogleCepheidProvider = 76,
  RespiratorProvider = 77,
  GroupVisitCoordinator = 78,
  GroupVisitFacilitator = 79,
  AutomatedTemplateEditor = 80,
  UnlicensedMedicalAssistant = 81,
  MediaAndSocialResponseTeam = 82,
  MassCancellation = 83,
  ParetoAdmin = 84,
  CentralBillingSpecialist = 85,
  LicensedPracticalNurse = 86,
  HelpOutActive = 87,
  RadiologicTechnologist = 88,
  UltrasoundTechnician = 89,
  AutoReplyTemplateManager = 90,
}

export default Role;
