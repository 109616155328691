import MicroFrontendConfig from './types/MicroFrontendConfig';
import Role from './types/Role';

const hostedMicroFrontends: Array<MicroFrontendConfig> = [
  {
    id: 'cdp',
    description: 'CDP Console',
    path: '/cdp',
    icon: 'faFire',
    localPort: 8002,
    authorizedRoles: [Role.Engineer],
    oauthScope:
      'user/Patient.read user/Practitioner.read user/Location.read user/Device.read user/Observation.read user/Procedure.read user/Questionnaire.read user/QuestionnaireResponse.read user/ValueSet.read user/CodeSystem.read',
  },
  {
    id: 'enterprise-portal',
    description: 'Enterprise Portal',
    path: '/enterprise-portal',
    icon: 'faBuilding',
    localPort: 8001,
    authorizedRoles: [Role.ClientServices, Role.Engineer],
  },
  {
    id: 'outreach-manager',
    description: 'Outreach Manager',
    path: '/outreach-manager',
    icon: 'faMegaphone',
    localPort: 8003,
    authorizedRoles: [Role.Engineer],
  },
  {
    id: 'billing-dashboard',
    description: 'Billing Dashboard',
    path: '/billing-dashboard',
    icon: 'faDollarSign',
    localPort: 8004,
    authorizedRoles: [Role.BillingAdmin, Role.Engineer],
  },
];

export default hostedMicroFrontends;
